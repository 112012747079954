import { Injectable, computed, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import {
  CommonChartMetrics,
  LatencyChartMetrics,
  PromptMetricType,
} from '@orquesta/models/metrics';

import { PromptsMetricsActions } from '../actions';
import { PromptDataset } from '../models';
import { promptsMetricsFeature } from '../reducer';

@Injectable()
export class PromptMetricsFacade {
  private store = inject(Store);

  public overview = this.store.selectSignal(
    promptsMetricsFeature.selectOverview,
  );

  public query = this.store.selectSignal(promptsMetricsFeature.selectQuery);
  public intervalQuery = computed(() => this.query().interval);

  public isLoading = this.store.selectSignal(
    promptsMetricsFeature.selectLoading,
  );

  public refreshing = this.store.selectSignal(
    promptsMetricsFeature.selectRefreshing,
  );

  public lastUpdated = this.store.selectSignal(
    promptsMetricsFeature.selectLastUpdated,
  );

  public requests = this.store.selectSignal(
    promptsMetricsFeature.selectRequests,
  );

  public costs = this.store.selectSignal(promptsMetricsFeature.selectCost);

  public score = this.store.selectSignal(promptsMetricsFeature.selectScore);

  public latency = this.store.selectSignal(promptsMetricsFeature.selectLatency);

  public errorRate = this.store.selectSignal(
    promptsMetricsFeature.selectErrorRate,
  );

  public modelsDataset = this.store.selectSignal(
    promptsMetricsFeature.selectModelsDataset,
  );

  public promptsDataset = this.store.selectSignal(
    promptsMetricsFeature.selectPromptsDataset,
  );

  public deploymentsDataset = this.store.selectSignal(
    promptsMetricsFeature.selectDeploymentsDataset,
  );

  public currentDatasetSelected = this.store.selectSignal(
    promptsMetricsFeature.selectSelectedDataset,
  );

  public minutesSelected = this.store.selectSignal(
    promptsMetricsFeature.selectSelectedMinutes,
  );

  public updateSelectedDataset(datasetKey: PromptDataset) {
    this.store.dispatch(
      PromptsMetricsActions.updateSelectedDataset({ datasetKey }),
    );
  }

  public updateSelectedChart(chart: PromptMetricType) {
    this.store.dispatch(PromptsMetricsActions.updateSelectedChart({ chart }));
  }

  public getChartsMetrics(dataType: PromptMetricType) {
    const data = {
      [PromptMetricType.Requests]: this.requests,
      [PromptMetricType.Costs]: this.costs,
      [PromptMetricType.Score]: this.score,
      [PromptMetricType.Latency]: this.latency,
      [PromptMetricType.ErrorRate]: this.errorRate,
    }[dataType];

    return data() as (CommonChartMetrics | LatencyChartMetrics)[];
  }
}
