import { provideEffects } from '@ngrx/effects';
import { provideState } from '@ngrx/store';

import { PromptMetricsEffects } from './lib/effects';
import { PromptMetricsFacade } from './lib/facades';
import { promptsMetricsFeature } from './lib/reducer';

export * from './lib/actions';
export * from './lib/effects';
export * from './lib/models';
export * from './lib/reducer';

const promptMetricsProviders = [
  provideState(promptsMetricsFeature),
  provideEffects(PromptMetricsEffects),
  PromptMetricsFacade,
];

export { PromptMetricsFacade, promptMetricsProviders };
