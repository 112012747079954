import {
  CommonChartMetrics,
  GetMetricsDto,
  LatencyChartMetrics,
  MetricDatasetType,
  MetricIntervalMinuteConversion,
  ModelMetricDataset,
  PromptMetricDataset,
  PromptMetricType,
  PromptsOverview,
} from '@orquesta/models/metrics';

export interface PromptsMetricsQuery extends GetMetricsDto {
  chartDataType?: PromptMetricType;
  datasetType?: MetricDatasetType;
}

export interface PromptsMetrics {
  requests: CommonChartMetrics[];
  cost: CommonChartMetrics[];
  score: CommonChartMetrics[];
  errorRate: CommonChartMetrics[];
  latency: LatencyChartMetrics[];
  modelsDataset: ModelMetricDataset[];
  promptsDataset: PromptMetricDataset[];
  deploymentsDataset: PromptMetricDataset[];
  overview: PromptsOverview;
}

export type PromptsLoadingProps = keyof PromptsMetrics;

export enum PromptDataset {
  Models = 'modelsDataset',
  Prompts = 'promptsDataset',
  Deployments = 'deploymentsDataset',
}

export interface PromptsMetricsState extends PromptsMetrics {
  selectedDataset: PromptDataset;
  selectedChart: PromptMetricType;
  selectedMinutes: MetricIntervalMinuteConversion;
  query: PromptsMetricsQuery;
  loading: Record<PromptsLoadingProps, boolean>;
  refreshing: boolean;
  lastUpdated: string;
}
