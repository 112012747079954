import { createActionGroup, emptyProps, props } from '@ngrx/store';
import {
  CommonChartMetrics,
  GetMetricsDto,
  LatencyChartMetrics,
  MetricDataset,
  MetricIntervalMinuteConversion,
  PromptMetricType,
  PromptsOverview,
} from '@orquesta/models/metrics';

import { PromptDataset } from '../models';

export const PromptsMetricsActions = createActionGroup({
  source: 'PromptsMetrics',
  events: {
    setMinutes: props<{ minutes: MetricIntervalMinuteConversion }>(),
    setMinutesSuccess: props<{
      query: GetMetricsDto & { minutes?: MetricIntervalMinuteConversion };
    }>(),

    setRequests: props<{
      data: CommonChartMetrics[] & { minutes?: MetricIntervalMinuteConversion };
    }>(),
    setRequestsFailure: emptyProps(),

    setCosts: props<{ data: CommonChartMetrics[] }>(),
    setCostsFailure: emptyProps(),

    setLatency: props<{ data: LatencyChartMetrics[] }>(),
    setLatencyFailure: emptyProps(),

    setAvgScore: props<{ data: CommonChartMetrics[] }>(),
    setAvgScoreFailure: emptyProps(),

    setHitRate: props<{ data: CommonChartMetrics[] }>(),
    setHitRateFailure: emptyProps(),

    setErrorRate: props<{ data: CommonChartMetrics[] }>(),
    setErrorRateFailure: emptyProps(),

    updateSelectedChart: props<{ chart: PromptMetricType }>(),

    setOverview: props<{ data: PromptsOverview }>(),
    setOverviewFailure: emptyProps(),

    refreshDataset: emptyProps(),
    updateSelectedDataset: props<{ datasetKey: PromptDataset }>(),

    setDataset: props<{ data: MetricDataset[]; datasetKey: PromptDataset }>(),
    setDatasetFailure: props<{ datasetKey: PromptDataset }>(),

    Refresh: emptyProps(),
    Reset: emptyProps(),
  },
});
