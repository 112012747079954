import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import { LocalStorageKeys } from '@orquesta/models/common';
import {
  MetricIntervalGranularity,
  MetricIntervalMinuteConversion,
  PromptMetricType,
  PromptsOverview,
} from '@orquesta/models/metrics';
import { DateTime } from 'luxon';

import { CommonActions } from '@orq/state/common';
import { PromptsMetricsActions } from '../actions';
import {
  PromptDataset,
  PromptsMetricsQuery,
  PromptsMetricsState,
} from '../models';

export const initialState: PromptsMetricsState = {
  requests: [],
  cost: [],
  score: [],
  errorRate: [],
  latency: [],
  modelsDataset: [],
  promptsDataset: [],
  deploymentsDataset: [],
  overview: {} as PromptsOverview,
  lastUpdated: DateTime.now().toISO(),
  query: {
    interval:
      MetricIntervalGranularity[MetricIntervalMinuteConversion.ThirtyDays],
  } as PromptsMetricsQuery,
  selectedDataset: PromptDataset.Deployments,
  selectedChart: PromptMetricType.Requests,
  selectedMinutes: MetricIntervalMinuteConversion.ThirtyDays,
  loading: {
    requests: true,
    cost: true,
    score: true,
    errorRate: true,
    modelsDataset: true,
    promptsDataset: true,
    deploymentsDataset: true,
    latency: true,
    overview: true,
  },
  refreshing: false,
};

export const reducer = createReducer(
  initialState,
  on(CommonActions.resetWorkspace, PromptsMetricsActions.reset, () => {
    const { selectedMinutes, ...state } = initialState;
    const timeInterval = localStorage.getItem(LocalStorageKeys.TimeInterval);
    const parsedTimeInterval = timeInterval ? Number(timeInterval) : null;
    return {
      selectedMinutes:
        parsedTimeInterval ?? MetricIntervalMinuteConversion.ThirtyDays,
      ...state,
    };
  }),
  on(PromptsMetricsActions.setMinutes, (state, { minutes }) => ({
    ...state,
    requests: [],
    hitRate: [],
    cost: [],
    score: [],
    latency: [],
    errorRate: [],
    modelsDataset: [],
    promptsDataset: [],
    selectedMinutes: minutes,
    lastUpdated: DateTime.now().toISO(),
  })),
  on(PromptsMetricsActions.setMinutesSuccess, (state, { query }) => {
    return {
      ...state,
      query: {
        ...query,
      },
      selectedMinutes: query.minutes ?? state.selectedMinutes,
      loading: initialState.loading,
    };
  }),
  on(PromptsMetricsActions.setRequests, (state, { data }) => ({
    ...state,
    requests: data,
    loading: {
      ...state.loading,
      requests: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setRequestsFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      requests: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setCosts, (state, { data }) => ({
    ...state,
    cost: data,
    loading: {
      ...state.loading,
      cost: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setCostsFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      cost: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setLatency, (state, { data }) => ({
    ...state,
    latency: data,
    loading: {
      ...state.loading,
      latency: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setLatencyFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      latency: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setAvgScore, (state, { data }) => ({
    ...state,
    score: data,
    loading: {
      ...state.loading,
      score: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setAvgScoreFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      score: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setErrorRate, (state, { data }) => ({
    ...state,
    errorRate: data,
    loading: {
      ...state.loading,
      errorRate: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setErrorRateFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      errorRate: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.updateSelectedDataset, (state, { datasetKey }) => ({
    ...state,
    selectedDataset: datasetKey,
    loading: {
      ...state.loading,
      [datasetKey]: true,
    },
  })),
  on(PromptsMetricsActions.setDataset, (state, { data, datasetKey }) => ({
    ...state,
    [datasetKey]: data,
    loading: {
      ...state.loading,
      [datasetKey]: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setDatasetFailure, (state, { datasetKey }) => ({
    ...state,
    loading: {
      ...state.loading,
      [datasetKey]: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setOverview, (state, { data }) => ({
    ...state,
    overview: data ?? {},
    loading: {
      ...state.loading,
      overview: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.setOverviewFailure, (state) => ({
    ...state,
    loading: {
      ...state.loading,
      overview: false,
    },
    refreshing: false,
  })),
  on(PromptsMetricsActions.updateSelectedChart, (state, { chart }) => ({
    ...state,
    selectedChart: chart,
  })),
  on(PromptsMetricsActions.refresh, (state) => ({
    ...state,
    loading: initialState.loading,
    refreshing: true,
    lastUpdated: DateTime.now().toISO(),
  })),
);

export const promptsMetricsFeature = createFeature({
  name: 'promptsMetrics',
  reducer,
  extraSelectors: ({ selectLoading }) => {
    const simpleSelectors = {
      selectRequestLoading: createSelector(
        selectLoading,
        (loading) => loading.requests,
      ),
      selectCostsLoading: createSelector(
        selectLoading,
        (loading) => loading.cost,
      ),
      selectAvgScoreLoading: createSelector(
        selectLoading,
        (loading) => loading.score,
      ),
    };

    return { ...simpleSelectors };
  },
});
