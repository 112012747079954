import { inject } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { PromptsMetricsService } from '@orquesta/data/metrics';
import { LocalStorageKeys } from '@orquesta/models/common';
import {
  CommonChartMetrics,
  MetricIntervalGranularity,
  MetricIntervalMinuteConversion,
} from '@orquesta/models/metrics';
import { DataPropagationActions } from '@orquesta/state/data-propagation';
import { workspacesFeature } from '@orquesta/state/workspaces';
import {
  filterNil,
  getStartEndDateByMinutes,
  match,
} from '@orquesta/utils/common';
import { catchError, filter, map, of, switchMap, take, tap } from 'rxjs';

import { CommonActions } from '@orq/state/common';
import { PromptsMetricsActions } from '../actions';
import { PromptDataset } from '../models';
import { promptsMetricsFeature } from '../reducer';

export const setQuerySuccess = createEffect(
  (actions = inject(Actions), store = inject(Store)) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutes),
      switchMap(({ minutes }) => {
        return store.select(workspacesFeature.selectActiveWorkspaceId).pipe(
          filterNil(),
          take(1),
          map(() => {
            return PromptsMetricsActions.setMinutesSuccess({
              query: {
                ...getStartEndDateByMinutes(minutes),
                interval: MetricIntervalGranularity[minutes],
                minutes,
              },
            });
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const setRequests = createEffect(
  (
    actions = inject(Actions),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return promptMetricsService.getTotalRequests(query).pipe(
          map((requests) => {
            const actionPayload = {
              data: requests,
            } as {
              data: CommonChartMetrics[] & {
                minutes?: MetricIntervalMinuteConversion;
              };
            };

            if (query.minutes !== undefined) {
              actionPayload.data.minutes = query.minutes;
            }

            return PromptsMetricsActions.setRequests(actionPayload);
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const updatePromptMetricsLocalStorageEffect = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setRequests),
      filter(({ data }) => 'minutes' in data),
      tap(({ data }) => {
        if (data.minutes) {
          localStorage.setItem(
            LocalStorageKeys.TimeInterval,
            JSON.stringify(data.minutes),
          );
        }
      }),
    );
  },
  { functional: true, dispatch: false },
);

export const setAvgScore = createEffect(
  (
    actions = inject(Actions),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return promptMetricsService.getAvgScore(query).pipe(
          map((requests) => {
            return PromptsMetricsActions.setAvgScore({ data: requests });
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const setCosts = createEffect(
  (
    actions = inject(Actions),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return promptMetricsService.getCosts(query).pipe(
          map((requests) => {
            return PromptsMetricsActions.setCosts({ data: requests });
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const setLatency = createEffect(
  (
    actions = inject(Actions),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return promptMetricsService.getLatency(query).pipe(
          map((requests) => {
            return PromptsMetricsActions.setLatency({ data: requests });
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const setErrorRate = createEffect(
  (
    actions = inject(Actions),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return promptMetricsService.getErrorRate(query).pipe(
          map((data) => {
            return PromptsMetricsActions.setErrorRate({ data });
          }),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const setDataset = createEffect(
  (
    actions = inject(Actions),
    store = inject(Store),
    promptMetricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.refreshDataset),
      concatLatestFrom(() => [
        store.select(promptsMetricsFeature.selectSelectedDataset),
        store.select(promptsMetricsFeature.selectQuery),
      ]),
      switchMap(([, datasetKey, query]) => {
        const service = match(datasetKey, {
          [PromptDataset.Models]: () =>
            promptMetricsService.getModelDataset(query),
          [PromptDataset.Prompts]: () =>
            promptMetricsService.getPromptDataset(query),
          [PromptDataset.Deployments]: () =>
            promptMetricsService.getEndpointDataset(query),
        });

        return service.pipe(
          map((data) => {
            return PromptsMetricsActions.setDataset({ data, datasetKey });
          }),
          catchError(() =>
            of(PromptsMetricsActions.setDatasetFailure({ datasetKey })),
          ),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const fetchOverview = createEffect(
  (
    actions = inject(Actions),
    metricsService = inject(PromptsMetricsService),
  ) => {
    return actions.pipe(
      ofType(PromptsMetricsActions.setMinutesSuccess),
      switchMap(({ query }) => {
        return metricsService.overview(query).pipe(
          map((data) => PromptsMetricsActions.setOverview({ data })),
          catchError(() => of(PromptsMetricsActions.setOverviewFailure())),
        );
      }),
    );
  },
  {
    functional: true,
  },
);

export const refreshDataset = createEffect(
  (actions = inject(Actions)) => {
    return actions.pipe(
      ofType(
        PromptsMetricsActions.setMinutesSuccess,
        PromptsMetricsActions.updateSelectedDataset,
        PromptsMetricsActions.refresh,
      ),
      map(() => PromptsMetricsActions.refreshDataset()),
    );
  },
  {
    functional: true,
  },
);

export const refreshMetrics = createEffect(
  (actions = inject(Actions), store = inject(Store)) => {
    return actions.pipe(
      ofType(
        PromptsMetricsActions.refresh,
        DataPropagationActions.propagateDone,
        CommonActions.resetWorkspace,
      ),
      switchMap(() =>
        store
          .select(promptsMetricsFeature.selectSelectedMinutes)
          .pipe(filterNil()),
      ),
      map((minutes) => PromptsMetricsActions.setMinutes({ minutes })),
    );
  },
  {
    functional: true,
  },
);
